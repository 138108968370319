import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

const AUTH_API = 'http://18.188.151.234:3001/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //private loggedInStutes = false;
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private apiURL = environment.apiURL;
  loginData: any = localStorage.getItem('currentUser');
  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(this.loginData)
    );
    console.log('token', this.currentUserSubject);
    this.currentUser = this.currentUserSubject.asObservable();
  }


  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  getAccessToken(): string {
    if (this.currentUserValue.token === null) {
      return '';
    } else {
      return this.currentUserValue.token;
    }
  }

  getFileHeader = () => {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('authorization', 'jwt ' + this.getAccessToken());
    return { headers };
  }
  getImageHeader = () => {
    let headers = new HttpHeaders();
    headers = headers.append('authorization', 'jwt ' + this.getAccessToken());
    return { headers };
  }

  getJSONHeader = () => {
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('authorization', 'jwt ' + this.getAccessToken());
    return { headers };
  }

  public change_password(body: any) {
    return this.http
      .post(this.apiURL + 'change-password', body, this.getJSONHeader()).pipe(
        map((res: any) => {
          console.log('this is res upload', res);
          return res;
        })
      );
  }

  public profile_details_update(body: any) {
    return this.http.post(this.apiURL + 'profile-detail-update', body, this.getJSONHeader())
      .pipe(
        map((res: any) => {
          console.log('this is res upload', res);
          return res;
        })
      );
  }

  public profile_update(formData: FormData) {
    return this.http
      .post(this.apiURL + 'profile-update', formData, this.getImageHeader())
      .pipe(
        map((res: any) => {
          console.log('this is res upload', res);
          return res;
        })
      );
  }

  login(email: string, password: string) {
    let Url = this.apiURL;
    return this.http
      .post<any>(Url + 'login', { email, password })
      .pipe(
        map((user) => {
          // console.log('userData', user);
          let userData = {
            id: user.data.id,
            name: user.data.name,
            email: user.data.email,
            token: user.data.token,
            role: user.data.role,
            message: user.message,
            company_email: user.data.company_email
          };
          localStorage.setItem('currentUser', JSON.stringify(userData));
          localStorage.setItem('token_', user.data.token);
          localStorage.setItem("id", userData.id)
          this.currentUserSubject.next(userData);
          return user;
        }, httpOptions)
      );
  }

  register(formData: FormData): Observable<any> {
    let Url = this.apiURL;
    return this.http.post(Url + 'register', formData);
  }

  forgotPassword(body: any) {
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    return this.http.post<any>(this.apiURL + 'forgot-password', body, { headers });
  }

  resetPassword(body: any) {
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    return this.http.post<any>(this.apiURL + 'reset-password', body, { headers });
  }

  verifyEmail(token: any) {
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    return this.http.get<any>(this.apiURL + 'verify-user-email/' + token, { headers });
  }

  getUserDetails(id: any) {
    return this.http.get<any>(this.apiURL + 'user?id=' + id, this.getJSONHeader());
  }

  getCompanyById(id: any, token: any) {
    if (!token) token = this.getAccessToken();
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('authorization', 'jwt ' + token);
    let Url = this.apiURL;
    return this.http.get<any>(this.apiURL + 'company/' + id, { headers });
  }


  getPrivacyPolicy() {
    return this.http.get<any>(this.apiURL + 'raw-data?type=privacy_policy');
  }

  getTermsCondition() {
    return this.http.get<any>(this.apiURL + 'raw-data?type=terms_condition');
  }

  logout() {
    localStorage.clear();
    localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigateByUrl('/signin');
  }
}