import { Component, OnInit } from '@angular/core';
import { CumulusService } from 'src/app/services/cumulus.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router, } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  companiesList: any
  token: any
  companyCount: any
  searchText: any
  companyId: any
  selectedCompanyId: any
  companyDetails: any
  childmessage = false;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  imageSrc: any
  name: any
  email: any;
  address: any;
  selectedItem: any;
  constructor(private cumulusservice: CumulusService, private router: Router, private toastr: ToastrService) {
    this.token = localStorage.getItem("token_")
  }

  ngOnInit(): void {
    this.getCompaniesAPI();
    this.selectedItem = this.pagination.page;
  }
  pagination: any = {
    "page": 1,
    "limit": 10,
    "totalPageCount": 0,
    "search": null,
    "sort_by": "name",
    "sort_direction": "ASC",
    "totalItems": 0
  }

  counter(i: number) {
    return new Array(i);
  }

  onsort(colName: any) {
    if (this.pagination.sort_by != colName) this.pagination.sort_direction = 'ASC';
    else this.pagination.sort_direction = this.pagination.sort_direction === 'ASC' ? 'DESC' : 'ASC';
    this.pagination.sort_by = colName;
    this.getCompaniesAPI();
  }
  onPaginationClick = (pageNumber: any) => {
    this.pagination.page = pageNumber;
    this.selectedItem = pageNumber;
    this.getCompaniesAPI();
  }

  onSearch = (e: any) => {
    this.pagination.search = e.value;
    this.pagination.page = 1;
    this.getCompaniesAPI();
  }

  getCompaniesAPI() {
    let data: any = {
      "page": this.pagination.page,
      "limit": this.pagination.limit,
      "sort_by": this.pagination.sort_by,
      "sort_direction": this.pagination.sort_direction,
    }
    if (this.pagination.search) data.search = this.pagination.search;
    this.cumulusservice.getCompanies(data).subscribe(
      data => {
        if (data.status == "1") {
          this.companiesList = data.data.data;
          this.companyCount = data.data.totalResults
          this.name = data.data.data.name
          this.pagination.totalPageCount = Math.ceil(data.data.totalResults / this.pagination.limit);
          this.pagination.totalItems = data.data.totalResults
        } else {
          this.companiesList = [];
          this.pagination.totalPageCount = 0;
          this.pagination.totalItems = 0;
        }
      }, error => {
        this.companiesList = [];
        this.pagination.totalPageCount = 0;
        this.pagination.totalItems = 0;
      }
    )
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  deletedCompany() {
    console.log("cid---", this.selectedCompanyId)
    this.cumulusservice.deletedCompany(this.selectedCompanyId).subscribe(
      data => {
        if (data.status == "1") {
          this.toastr.success(data.message)
          this.getCompaniesAPI();
        }
        else {
          this.toastr.error(data.message)
        }

      },
    )
  }
  setCompanyId(id: any) {
    this.selectedCompanyId = id
  }

  getCompanyDetails(cId: any) {
    this.cumulusservice.getCompanyDetails(cId).subscribe(
      data => {

        if (data.status == "1") {
          this.companyDetails = data.data;
          this.name = data.data.name,
            this.email = data.data.email,
            this.address = data.data.address


        }
        else {
          this.toastr.error(data.message)

        }
      },
    )
  }

  onSubmit() {
    this.cumulusservice.comapnyEdit(this.name, this.email, this.address, null).subscribe(
      data => {
        console.log(data.message)
        if (data.message == "success") {

        }
        else {


        }
      })
  }
  deleteCompanyProfile() {
    const formData = new FormData();
    formData.append('image', "");
    //this.imageSrc = image.result as string;
    this.cumulusservice.comapnyProfileUpdate(formData).subscribe(
      (res: any) => {
        if (res) {
          this.toastr.success(res["message"])
        } else {
          this.toastr.success(res["message"])
        }
      },
      (error) => {
        this.toastr.error(error.message);
      }
    );
  }
  comapnyProfileUpdate(event: any) {
    this.uploadCompanyImage(event.target.files[0])

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      //	this.msg = "";
      this.imageSrc = reader.result;
    }
  }
  uploadCompanyImage = (image: any) => {
    const formData = new FormData();
    formData.append('image', image);
    this.imageSrc = image.result as string;
    this.cumulusservice.comapnyProfileUpdate(formData).subscribe(
      (res: any) => {
        if (res) {
          this.toastr.success(res["message"])
        } else {
          this.toastr.success(res["message"])
        }
      },
      (error) => {
        this.toastr.error(error.message);
      }
    );

  }

  gotoDashboard(companies_details: any) {
    this.companyId = companies_details.id;
    console.log("cd", companies_details.id)
    localStorage.setItem("companies_details", companies_details.id)
    this.router.navigate(['./dashboard/home']);

  }

  getPaginationData = (page: any) => {
    this.pagination.page = page;
    this.getCompaniesAPI();
  }

}
