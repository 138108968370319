import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-verifyemail',
  templateUrl: './verifyemail.component.html',
  styleUrls: ['./verifyemail.component.css']
})
export class VerifyemailComponent implements OnInit {

  token: any;
  constructor(private route: ActivatedRoute, 
              private toastr: ToastrService, 
              private router: Router,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.token = this.route.snapshot?.params?.token;
    if(this.token) {
      this.verifyToken();
    }
  }

  verifyToken() {
    this.authService.verifyEmail(this.token).subscribe(
      data => {
        if (data.status) {
          this.toastr.success(data.message)
          setTimeout(() => {
            this.router.navigate(['./signin']);
          }, 1000);
        }
        else {
          this.toastr.error(data.message)
        }
    })
  }

}
