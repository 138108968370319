import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
class ImageSnippet {
  constructor(public src: string, public file: File) { }
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  loginUserData: any;
  userDetails: any;
  role: any;
  companyLogo = localStorage.getItem("companyLogo");
  constructor(private user: AuthService) {
  }

  ngOnInit(): void {
    this.loginUserData = this.user.currentUserValue;
    this.role = localStorage.getItem("Role");
    console.log(this.loginUserData);
    this.getUserDetails()
  }

  getUserDetails() {
    this.user.getUserDetails(localStorage.getItem("user_id")).subscribe((data) => {
      console.log(data.message);
      if (data.status == '1') {
        this.userDetails = data.data;
      }
    });
  }

  togglemenu(e: any) {
    e.preventDefault();
    $("#pageWrappper").toggleClass("toggled");
  }

  onLogout = () => {
    this.user.logout();
  }

  getProfileName() {
    var matches = this.loginUserData?.name?.match(/\b(\w)/g);
    console.log(matches)
    return matches.join('').toUpperCase();
  }
}






