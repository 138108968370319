import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { element } from 'protractor';
import { link } from 'node:fs';
@Component({
  selector: 'app-shareartefact',
  templateUrl: './shareartefact.component.html',
  styleUrls: ['./shareartefact.component.css']
})
export class ShareartefactComponent implements OnInit {
  private apiURL = environment.apiURL;

  token: any
  user_token: any;
  url: any;
  viewCollectionData: any;
  artefactData: any;
  imageUrl: any;
  collectionowner: any;
  Title: any;
  collectionimg: any;
  collectiondescr: any;
  artefactsFormList: any;
  viewArtifectsData: any;
  artefactsFile: any[] = [];
  artefactsData: any
  defaultImageUrl: string = '';
  isViewImageModalOpen: boolean = false;
  constructor(private router: ActivatedRoute, public http: HttpClient) {

  }

  ngOnInit(): void {
    this.token = this.router.snapshot.paramMap.get('token');
    console.log("this.token", this.token);

    console.log("sharetoken", this.token)
    this.url = this.apiURL + "shared-artefacts/" + this.token
    this.http.get<any>(this.url)
      .subscribe(data => {
        let c: any = {};
        this.artefactsFormList = JSON.parse(JSON.stringify(data.data.data.inputs));
        let viewArtifectsData: any = {};
        data.data.data.inputs.forEach((element: { input_label: string | number; input_value: any; }) => {
          viewArtifectsData[element.input_label] = element.input_value;
        });
        this.viewArtifectsData = viewArtifectsData;
        // this.artefactsFile = (data.data.data.files && data.data.data.files.length > 0) ? data.data.data.files[0].link : '';
        // this.defaultImageUrl = (data.data.data.files && data.data.data.files.length > 0) ? data.data.data.files[0].link : '';

        if (data.data.data.files) {
          data.data.data.files.forEach((e: any) => {
            this.artefactsFile.push({
              image: e.link,
              thumbImage: e.link,
              isPreview: e.is_preview,
              isActive: e.is_preview ? 1 : 0
            });
            if(e.is_preview)
              this.defaultImageUrl = e.link;
          });
          // this.defaultImageUrl = this.artefactsFile[0].image;
        }
        console.log(this.artefactsFile, this.defaultImageUrl);
        // data.data.data.inputs.map((i: { input_label: any; input_value: any; }) => { c[i.input_label] = i.input_value })
        // this.artefactsData = c
        // console.log(c);

      });
  }

  parseClassificationString(e: any) {
    try {
      return JSON.parse(e);
    } catch (error) {
      return e;
    }
  }

  changePrimaryDisplay(i: number) {
    this.defaultImageUrl = this.artefactsFile[i].image;
  }
  onPrimaryImageClick() {
    this.isViewImageModalOpen = true;
  }
  handleViewImageModalCloseEvent() {
    this.isViewImageModalOpen = false;
  }


}

