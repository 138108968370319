<div class="signinWrap creataccoutWrap p-0" [ngClass]="{'is-company': !is_individual}">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="formContent">

                    <div class="formData" style="max-width: 600px !important;">
                        <div class="logoWrap">
                            <img src="assets/img/logo-black.png" class="img-fluid" alt="" />
                            <!-- <h2>Collection viewing system</h2> -->
                        </div>
                        <div class="topData">
                            <h2>Create an Account</h2>
                            <p>Already have an account? <a routerLink="/signin">Sign in</a></p>
                        </div>
                        <div class="fromWrap">
                            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                                <label class="radiolabel">Register as</label>
                                <ul class="custom-radio">
                                    <label (click)="toggleCompanyIndividual(1)" class="custom-radio-block">Individual
                                        <input *ngIf="is_individual===1" type="radio" checked="checked" name="radio">
                                        <input *ngIf="is_individual!==1" type="radio" name="radio">
                                        <span class="checkmark"></span>
                                    </label>
                                    <label (click)="toggleCompanyIndividual(0)" class="custom-radio-block">Company
                                        <input *ngIf="is_individual===0" type="radio" checked="checked" name="radio">
                                        <input *ngIf="is_individual!==0" type="radio" name="radio">
                                        <span class="checkmark"></span>
                                    </label>
                                </ul>
                                <ul>
                                    <li class="full marbtm">
                                        <h3>User Details</h3>
                                    </li>
                                    <li class="half">
                                        <!-- <label>Name</label> -->
                                        <div class=" inputField form-group">
                                            <input autocomplete="off" type="text" formControlName="name" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                                                placeholder="Name*" />
                                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                <div *ngIf="f.name.errors.required"> Name is required</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="half">
                                        <div class=" inputField form-group">
                                            <input type="text" placeholder="Email*" formControlName="email"
                                                class="form-control"
                                                autocomplete="off"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                                <div *ngIf="f.email.errors.email">Email must be a valid email address
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="half">
                                        <div class="inputField form-group countrycode">
                                            <!-- <img src="assets/img/india.png" class="img-fluid" alt="" /> -->
                                            <input type="text" class="form-control" formControlName="country_code"
                                            [ngClass]="{ 'is-invalid': submitted && f.country_code.errors }"/>
                                            <div *ngIf="submitted && f.country_code.errors" class="invalid-feedback">
                                                <div *ngIf="f.country_code?.errors?.required">Country code is required</div>
                                                <div *ngIf="f.country_code?.errors?.pattern">Enter valid country code.
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" inputField form-group phoneno">
                                            <input type="text" placeholder="Phone Number*" formControlName="mobile"
                                                class="form-control"
                                                autocomplete="off"
                                                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                                <div *ngIf="f.mobile.errors.required">Phone number is required</div>
                                                <!-- <div *ngIf="f.mobile.errors.pattern">Enter 10 digit Mobile Number.
                                                </div> -->
                                            </div>
                                        </div>
                                    </li>

                                    <li class="full marbtm">
                                        <h3>Account password</h3>
                                    </li>
                                    <li class="half password">
                                        <div class=" inputField form-group">
                                            <input type="{{showEyePassword ? 'text' : 'password'}}"
                                            autocomplete="off"
                                                placeholder="Password*" formControlName="password" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                            <img (click)="showEyePassword = !showEyePassword"
                                                src="{{showEyePassword ? 'assets/img/eye-show.svg' : 'assets/img/eye-hide.svg' }}"
                                                class="img-fluid" alt="" />
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                                <div *ngIf="f.password.errors.pattern">Password must be
                                                    (Min 8 character, 1 Upper Case, 1 Special, 1 Number)</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="half password">
                                        <div class=" inputField form-group">
                                            <input type="{{showEyeCPassword ? 'text' : 'password'}}"
                                            autocomplete="off"
                                                placeholder="Confirm Password*" formControlName="confirmPassword"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                                            <img (click)="showEyeCPassword = !showEyeCPassword"
                                                src="{{showEyeCPassword ? 'assets/img/eye-show.svg' : 'assets/img/eye-hide.svg' }}"
                                                class="img-fluid" alt="" />
                                            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is
                                                    required
                                                </div>
                                                <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="full marbtm" *ngIf="is_individual===0">
                                        <h3>Company details</h3>
                                    </li>
                                    <li class="half" *ngIf="is_individual===0">
                                        <div class=" inputField form-group">
                                            <input autocomplete="off" type="text" placeholder="Company Name*"
                                                formControlName="company_name" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" />
                                            <div *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
                                                <div *ngIf="f.company_name.errors.required">Company name is required
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="half" *ngIf="is_individual===0">
                                        <div class=" inputField form-group">
                                            <input autocomplete="off" type="text" placeholder="Comapany Email*" formControlName="c_email"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.c_email.errors }" />
                                            <div *ngIf="submitted && f.c_email.errors" class="invalid-feedback">
                                                <div *ngIf="f.c_email.errors.required">Email is required</div>
                                                <div *ngIf="f.c_email.errors.email">Email must be a valid email address
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="full" *ngIf="is_individual===0">
                                        <div class="form-group">
                                            <textarea type="text" placeholder="Address*" formControlName="address"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                                <div *ngIf="f.address.errors.required">Address is required</div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="agreeCheck form-check p-0">
                                        <input type="checkbox" formControlName="acceptTerms" id="acceptTerms"
                                            class="styled-checkbox form-check-input d-none" style="margin-top:7px; "
                                            [ngClass]="{ 'is-invalid': submitted && f.acceptTerms.errors }" />&nbsp;
                                        <label for="acceptTerms" class="form-check-label" style="color: #6E6E6E;">I
                                            agree
                                            to the</label>
                                        <span data-toggle="modal" data-target="#addartefacts"> Privacy
                                                Policy</span>
                                            <label class="mx-2">and</label> <span data-toggle="modal" data-target="#terms_condition">Terms of
                                                Services</span>
                                        <div *ngIf="submitted && f.acceptTerms.errors" class="invalid-feedback">
                                            Accept T&C</div>
                                    </li>
                                    <li class="full">
                                        <div class="form-group btnSign">
                                            <button>Create Account</button>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                            <p class="copyright">A MAP Labs - OpEzee Initiative</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade formPopup termspopup" id="addartefacts" tabindex="-1" role="dialog"
    aria-labelledby="addartefactsLabel" aria-hidden="true">

    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header sticky-top bg-white">
                <div>
                </div>
                <button type="button" class="btn" data-toggle="modal" data-target="#addartefacts">X</button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div [innerHtml]=htmldata></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade formPopup termspopup" id="terms_condition" tabindex="-1" role="dialog"
    aria-labelledby="addartefactsLabel" aria-hidden="true">

    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header sticky-top bg-white">
                <div>
                </div>
                <button type="button" class="btn" data-toggle="modal" data-target="#terms_condition">X</button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div [innerHtml]=htmldata2></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
