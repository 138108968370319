<div class="signinWrap">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="formContent">

                    <div class="formData">
                        <div class="logoWrap">
                            <img src="assets/img/logo-black.png" class="img-fluid" alt="" />
                            <!-- <h2>Collection viewing system</h2> -->
                        </div>
                        <div class="topData">
                            <h2>Sign In</h2>
                            <p>Don’t have an account yet? <a routerLink="/signup">Register free</a></p>
                        </div>
                        <div class="fromWrap">
                            <form class="form-center" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                <ul>
                                    <li class="full">
                                        <!-- <label>Email</label>
                                        <div class="inputField form-group">
                                            <input type="email" class="" name="email" [(ngModel)]="form.email"
                                                #email="ngModel" required>
                                            <img src="assets/img/ico-mail.png" class="img-fluid" alt="" />
                                            <span class="alert alert-danger" role="alert"
                                                *ngIf="email.errors && f.submitted">
                                                Email is required!
                                            </span>
                                        </div> -->
                                        <!-- <label>Email</label> -->
                                        <div class=" inputField form-group">
                                            <input autofocus type="text" placeholder="Email" formControlName="email"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                                <div *ngIf="f.email.errors.email">Email must be a valid email address
                                                </div>
                                            </div>
                                        </div>

                                    </li>
                                    <li class="full password">
                                        <!-- <label>Password</label>
                                        <div class="inputField form-group">
                                            <input type="password" class="" name="password" [(ngModel)]="form.password"
                                                #password="ngModel" required>
                                            <img src="assets/img/ico-password.png" class="img-fluid" alt="" />
                                            <span class="alert alert-danger" role="alert"
                                                *ngIf="password.errors && f.submitted">
                                                Password is required!
                                            </span>
                                        </div> -->
                                        <!-- <label>Password</label> -->
                                        <div class="inputField form-group">
                                            <input type="{{showEye ? 'text' : 'password'}}" placeholder="Password"
                                                formControlName="password" class="form-control mb-0"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                            <img (click)="showEye = !showEye"
                                                src="{{showEye ? 'assets/img/eye-show.svg' : 'assets/img/eye-hide.svg' }}"
                                                class="img-fluid" alt="" />
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                                <div *ngIf="f.password.errors.pattern">Password must be
                                                    (Min 8
                                                    character, 1 Upper Case, 1 Special, 1 Number)</div>
                                            </div>

                                        </div>

                                    </li>
                                    <li class="bottomFields full">
                                        <div class="leftWrap form-group position-relative">
                                            <input type="checkbox" class="styled-checkbox form-check-input"
                                                id="rememberMe">
                                            <label for="rememberMe">Remember me</label>
                                        </div>
                                        <div class="rightWrap form-group">
                                            <a routerLink="/forgot">Forgot password ?</a>
                                        </div>
                                    </li>
                                    <li class="full">
                                        <div class="form-group btnSign">
                                            <button>Sign in</button>
                                        </div>
                                        <!--  <div class="form-group">
                                            <div class="alert alert-danger" role="alert" *ngIf="f.submitted">
                                                Login failed:
                                            </div>
                                        </div> -->
                                    </li>
                                </ul>
                            </form>

                            <p class="copyright">A MAP Labs - OpEzee Initiative</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="signinWrap">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="formContent">
                    <div class="logoWrap">
                        <img src="assets/img/logo.png" class="img-fluid" alt="" />
                        <h2>Collection viewing system</h2>
                    </div>
                    <div class="formData">
                        <div class="topData">
                            <h2>Sign in</h2>
                            <p>Don’t have an account yet? <a routerLink="/signup">Join Cumulus</a></p>
                        </div>
                        <div class="fromWrap">
                            <form>
                                <ul>
                                    <li class="full">
                                        <label>Email</label>
                                        <div class="inputField">
                                            <input type="email" />
                                            <img src="assets/img/ico-mail.png" class="img-fluid" alt="" />
                                        </div>
                                    </li>
                                    <li class="full">
                                        <label>Password</label>
                                        <div class="inputField">
                                            <input type="password" />
                                            <img src="assets/img/ico-password.png" class="img-fluid" alt="" />
                                        </div>
                                    </li>
                                    <li class="bottomFields full">
                                        <div class="leftWrap">
                                            <input class="styled-checkbox" id="rememberme" type="checkbox"
                                                value="value1">
                                            <label for="rememberme">Remember me</label>
                                        </div>
                                        <div class="rightWrap">
                                            <a routerLink="/forgot">Forgot password ?</a>
                                        </div>
                                    </li>
                                    <li class="full">
                                        <div class="ctaWrap">
                                            <a routerLink="/dashboard">Sign in</a>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->