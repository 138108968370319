import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { catchError, map } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ManageService {
  private apiURL = environment.apiURL;


  constructor(
    private auth: AuthService,
    private router: Router,
    public http: HttpClient
  ) { }


  getAccessToken(): string {
    if (this.auth.currentUserValue.token === null) {
      return '';
    } else {
      return this.auth.currentUserValue.token;
    }
  }

  getFormInput(company_id: any, form_type: any) {
    return this.http
      .get(this.apiURL + `form-input?company_id=${company_id}&form_type=${form_type}`, this.auth.getJSONHeader())
      .pipe(
        map((data: any): any => {
          return data;
        })
      );
  }

  getCollectionById(collectionId: any) {
    return this.http
      .get(this.apiURL + `collections/${collectionId}`, this.auth.getJSONHeader())
      .pipe(
        map((data: any): any => {
          return data;
        })
      );
  }

  addFormInput(body: any) {
    return this.http
      .post(this.apiURL + "form-input", body, this.auth.getJSONHeader())
      .pipe(
        map((data: any): any => {
          return data;
        })
      );
  }

  deleteFormIndut(id: any) {
    return this.http
      .delete<any>(this.apiURL + 'form-input?id=' + `${id}`, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  updateFormInput(obj: any) {
    return this.http
      .put<any>(this.apiURL + 'form-input', obj, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  importTemplate(importType: any) {
    let headers = new HttpHeaders();
    headers = headers.append('content-type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('authorization', 'jwt ' + this.getAccessToken());
    return this.http
      .post(this.apiURL + 'import-sample?type=' + importType, {}, { headers, responseType: 'blob' as 'json' })
      .pipe(
        map((data: any): any => {
          return data;
        })
      );
  }

  uploadArtefactsForm(body: any) {
    return this.http
      .post(this.apiURL + 'artefacts/import', body, this.auth.getFileHeader())
      .pipe(
        map((data: any): any => {
          return data;
        })
      );
  }

  uploadCollectionForm(body: any) {
    return this.http
      .post(this.apiURL + 'collections/import', body, this.auth.getFileHeader())
      .pipe(
        map((data: any): any => {
          return data;
        })
      );
  }

  getSubscriptionListAPI() {
    return this.http
      .get(this.apiURL + 'subscription', this.auth.getJSONHeader())
      .pipe(
        map((data: any): any => {
          return data;
        })
      );
  }

  deleteSubscriptionAPI(id: any) {
    return this.http
      .delete(this.apiURL + `subscription?id=` + id, this.auth.getJSONHeader())
      .pipe(
        map((data: any): any => {
          return data;
        })
      );
  }

  addSubscriptionAPI(body: any) {
    return this.http
      .post(this.apiURL + 'subscription', body, this.auth.getJSONHeader())
      .pipe(
        map((data: any): any => {
          return data;
        })
      );
  }

  subscribePlanAPI(subscription_id: any) {
    return this.http
      .post(this.apiURL + 'subscribe-plan', { subscription_id }, this.auth.getJSONHeader())
      .pipe(
        map((data: any): any => {
          return data;
        })
      );
  }

  subscribePlanCompletePaymentAPI(data: any) {
    return this.http
      .post(this.apiURL + 'subscribe-plan-complete-payment', data, this.auth.getJSONHeader())
      .pipe(map((data: any): any => { return data; }));
  }

  UpdateSubscriptionAPI(body: any) {
    return this.http
      .put(this.apiURL + `subscription`, body, this.auth.getJSONHeader())
      .pipe(map((data: any): any => { return data; }));
  }

  GetSubscriptionById(id: any) {
    return this.http
      .get(this.apiURL + 'subscription?subscription_id=' + id, this.auth.getJSONHeader())
      .pipe(map((data: any): any => { return data; }));
  }


  //  Permission & Roles #Nikhil


  GetPermissionListAPI() {
    return this.http
      .get(this.apiURL + 'roles/permissionList', this.auth.getJSONHeader())
      .pipe(map((data: any): any => { return data; }));
  }

  GetRoleListAPI(companyId: any) {
    return this.http
      .get(this.apiURL + `roles?company_id=` + companyId, this.auth.getJSONHeader())
      .pipe(map((data: any): any => { return data; }));
  }

  GetRoleByIdAPI(roleId: any) {
    return this.http
      .get(this.apiURL + `roles/` + roleId, this.auth.getJSONHeader())
      .pipe(map((data: any): any => { return data; }));
  }

  AddRoleAPI(data: any) {
    return this.http
      .post(this.apiURL + `roles`, data, this.auth.getJSONHeader())
      .pipe(map((data: any): any => { return data; }));
  }

  UpdateRoleAPI(data: any) {
    return this.http
      .put(this.apiURL + `roles`, data, this.auth.getJSONHeader())
      .pipe(map((data: any): any => { return data; }));
  }

  DeleteRoleAPI(roleId: any) {
    return this.http
      .delete(this.apiURL + `roles?id=` + roleId, this.auth.getJSONHeader())
      .pipe(map((data: any): any => { return data; }));
  }


}



