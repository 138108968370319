import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const userRoles: string = this.authService.currentUserValue.role;  // <--------- get the current user's roles
    const routeRoles: string[] = route.data['roles'];   // <------- Will get the roles arry you defined in your router config
    if(routeRoles.includes(userRoles)
    ) {
        return true;
    } else {
        if(userRoles === 'Super-admin') {
            this.router.navigate(['/company']);            
        } else {
            this.router.navigate(['/dashboard/home']);
        }
        return false;
    }
    /*
      Now you can do your logic to determine if the user has the appropriate role.
      If they do return true
      Else use router to set a redirect route to /user url or whereever you feel like and return false;
    */

  }
}