import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';

import { CompanyComponent } from './pages/company/company.component';

import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AuthGuardService, NonAuthGuardService } from './services/auth-guard.service';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from "angular-datatables";
import { ShareComponent } from './pages/share/share.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SignupsubscriptionComponent } from './pages/signupsubscription/signupsubscription.component';
import { ShareartefactComponent } from './pages/shareartefact/shareartefact.component';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { AutoFocus } from './autofocus.directive';
import { ShareCollectionDetailComponent } from './pages/sharecollectiondetail/sharecollectiondetail.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { RoleGuard } from './services/role-guard.service';
import { ComponentsModule } from './components/components.module';
import { VerifyemailComponent } from './pages/verifyemail/verifyemail.component';
const apiURL = environment.apiURL;


@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    SignupComponent,
    ForgotpasswordComponent,
    ResetpasswordComponent,
    CompanyComponent,
    HeaderComponent,
    FooterComponent,
    ShareComponent,
    ShareCollectionDetailComponent,
    SignupsubscriptionComponent,
    ShareartefactComponent,
    AutoFocus,
    VerifyemailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    Ng2SearchPipeModule,
    BrowserAnimationsModule,
    DataTablesModule,
    NgxPaginationModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({
      showForeground: true,
      delay: 100,
      // maxTime: 10000,
      exclude: [
        apiURL + "artefacts/list",
        apiURL + "artefact-filter-option",
        apiURL + "user",
        apiURL + "collections?",
        apiURL + "artists?",
        apiURL + "classification?",
        apiURL + "logs?",
        apiURL + "members?",
        apiURL + "roles",
      ],
    }),
    ComponentsModule
  ],
  providers: [
    AuthGuardService, DatePipe, NonAuthGuardService, RoleGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
