import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { element } from 'protractor';
import { link } from 'node:fs';
@Component({
  selector: 'app-sharecollectiondetail',
  templateUrl: './sharecollectiondetail.component.html',
  styleUrls: ['./sharecollectiondetail.component.css']
})
export class ShareCollectionDetailComponent implements OnInit {
  private apiURL = environment.apiURL;

  artefactId: any
  user_token: any;
  url: any;
  viewCollectionData: any;
  artefactData: any;
  imageUrl: any;
  collectionowner: any;
  Title: any;
  collectionimg: any;
  collectiondescr: any;
  artefactsFormList: any;
  viewArtifectsData: any;
  artefactsFile: any;
  artefactsData: any
  constructor(private router: ActivatedRoute, public http: HttpClient) {

  }

  ngOnInit(): void {
    this.artefactId = this.router.snapshot.paramMap.get('artefactId');
    console.log("this.artefactId", this.artefactId);

    console.log("sharetoken", this.artefactId)
    this.url = this.apiURL + "shared-artefacts/" + this.artefactId
    this.http.get<any>(this.url)
      .subscribe(data => {
        let c: any = {};
        this.artefactsFormList = JSON.parse(JSON.stringify(data.data.data.inputs));
        let viewArtifectsData: any = {};
        data.data.data.inputs.forEach((element: { input_label: string | number; input_value: any; }) => {
          viewArtifectsData[element.input_label] = element.input_value;
        });
        this.viewArtifectsData = viewArtifectsData;
        this.artefactsFile = (data.data.data.files && data.data.data.files.length > 0) ? data.data.data.files[0].link : '';

        // data.data.data.inputs.map((i: { input_label: any; input_value: any; }) => { c[i.input_label] = i.input_value })
        // this.artefactsData = c
        // console.log(c);

      });
  }

  parseClassificationString(e: any) {
    try {
      return JSON.parse(e);
    } catch (error) {
      return e;
    }
  }




}

