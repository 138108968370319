<div class="headerWrap">
    <nav class="navbar">
        <div class="container">
            <div class="navbar-header">
                <a class="navbar-brand logoWrap1"><img src="assets/img/logo.png" class="img-fluid imgLogo1"
                        alt="" /></a>

            </div>
            <ul class="nav">

                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['/signin']">
                        <button class="btn-primary" data-dismiss="modal">Log in</button>
                    </a>
                </li>

                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['/signup']">
                        <button class="btn-primary" data-dismiss="modal">Register</button>
                    </a>
                </li>


            </ul>
        </div>
    </nav>
</div>
<div class="container" style="color: #606060;">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12" *ngIf="viewArtifectsData">
                <div class="titleWrap">
                    <h2>{{this.viewArtifectsData.title}}</h2>
                </div>
                <div class="bigImgWrap" *ngIf="this.artefactsFile">
                    <div class="imgWrap">
                        <img src="{{this.artefactsFile}}" class="ig-fluid" alt="member.name" />
                    </div>
                </div>
                <br><br>
                <div class="titleWrap">
                    <h2>Catalogue Entry</h2>
                </div>
                <hr>
                <div *ngIf="artefactsFormList" class="catalogue-entry">
                    <div class="row justify-content-center">
                        <div class="col-md-3">
                            <p class="formField" *ngFor="let artefactsData of artefactsFormList">
                                <label>{{artefactsData?.input_label ? artefactsData?.input_label : '-'}}</label>
                            </p>
                        </div>
                        <div class="col-md-9">
                            <p *ngFor="let artefactsData of artefactsFormList">
                                <label *ngIf="artefactsData.input_label == 'Classification(s)'; else elseData;">
                                    <span *ngIf="artefactsData?.input_value">
                                        <span
                                            *ngFor="let item of parseClassificationString(artefactsData?.input_value); let i = index;">
                                            {{i+1 == parseClassificationString(artefactsData?.input_value).length ?
                                            item?.classification:
                                            item?.classification
                                            + ','}}

                                        </span>
                                    </span>
                                </label>
                                <ng-template #elseData>
                                    <label>
                                        {{artefactsData?.input_value ? artefactsData?.input_value : '-'}}
                                    </label>
                                </ng-template>

                            </p>
                        </div>
                    </div>
                </div>

                <!-- 
                    <div class="doc-upload">
                        <p>Related Documents</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Uploaded By</th>
                                    <th>Document</th>
                                    <th>Links</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="relatedDocList && relatedDocList.length > 0">
                                <tr *ngFor="let item of relatedDocList">
                                    <td>{{item?.created_on | date}}</td>
                                    <td class="colorGreen">{{item?.description}}</td>
                                    <td>{{item?.name}}</td>
                                    <td class="colorGreen"><a class="colorGreen" href="{{item?.document_path}}"
                                            target="_blank" rel="noopener noreferrer">
                                            {{item?.document_path.substring(item?.document_path.lastIndexOf('/') + 1)}}
                                        </a></td>
                                    <td>
                                        <a *ngIf="item?.links && item.links.length > 0" href="javascript:void(0)"
                                            data-toggle="modal" data-target="#viewLinkModal"
                                            (click)="viewLinks(item?.links)">View</a>
                                        <span *ngIf="!item?.links || item.links.length === 0">-</span>
                                    </td>
                                    <td>
                                        <button class="btn btnAction" data-toggle="modal"
                                            data-target="#deleteArtefactRelatedDocModal"
                                            (click)="onRemoveArtefactRelatedDocClick(item?.id)">
                                            <img src="assets/img/ico-trash.png" alt="" class=" img-fluid icoDelete" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!relatedDocList || relatedDocList.length === 0">
                                <tr>
                                    <td colspan="6" class="text-center">
                                        no records found.
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div class="doc-upload">
                        <p>Artefact Activity Log</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                    <th>Work Done By</th>
                                    <th>Document</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="activityLogList && activityLogList.length > 0">
                                <tr *ngFor="let item of activityLogList">
                                    <td>{{item?.activity_date | date}}</td>
                                    <td class="colorGreen">{{item?.description}}</td>
                                    <td>{{item?.work_done_by}}</td>
                                    <td class="colorGreen"><a class="colorGreen" href="{{item?.document_path}}"
                                            target="_blank" rel="noopener noreferrer">
                                            {{item?.document_path.substring(item?.document_path.lastIndexOf('/') + 1)}}
                                        </a></td>
                                    <td>
                                        <button class="btn btnAction" data-toggle="modal"
                                            data-target="#deleteArtefactActivityLogModal"
                                            (click)="onRemoveArtefactActivityLogClick(item?.id)">
                                            <img src="assets/img/ico-trash.png" alt="" class=" img-fluid icoDelete" />
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!activityLogList || activityLogList.length === 0">
                                <tr>
                                    <td colspan="5" class="text-center">
                                        no records found.
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div> -->
            </div>
        </div>
    </div>
</div>