import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private auth: AuthService, private _router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.isLoggedIn()) {
      return true;
    } else {
      this._router.navigate(['/signin']);
      return true;
    }

  }

  isLoggedIn(): boolean {
    //console.log("currentUserValue", this.auth.currentUserValue)
    if (this.auth.currentUserValue) {
      //alert(this.auth.currentUserValue.value)
      let token = this.auth.currentUserValue.token
      // console.log("authguard", token)
      let role = this.auth.currentUserValue.role
      // console.log("authguard", role)
      if (token) {

        return true;
      } else {

        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable()
export class NonAuthGuardService implements CanActivate {
  constructor(private auth: AuthService, private _router: Router) {}

  canActivate(): boolean {
    if (this.isLoggedIn()) {
      let role = this.auth.currentUserValue.role
      if(role === 'Super-admin')
        this._router.navigate(['/company']);
      else 
        this._router.navigate(['/dashboard/home']);
      return false;
    }
    return true;
  }

  isLoggedIn(): boolean {
    //console.log("currentUserValue", this.auth.currentUserValue)
    if (this.auth.currentUserValue) {
      //alert(this.auth.currentUserValue.value)
      let token = this.auth.currentUserValue.token
      // console.log("authguard", token)
      let role = this.auth.currentUserValue.role
      // console.log("authguard", role)
      if (token) {

        return true;
      } else {

        return false;
      }
    } else {
      return false;
    }
  }
}

