import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { CompanyComponent } from './pages/company/company.component';
import { AuthGuardService, NonAuthGuardService } from './services/auth-guard.service';
import { ShareComponent } from './pages/share/share.component';
import { SignupsubscriptionComponent } from './pages/signupsubscription/signupsubscription.component';
import { ShareartefactComponent } from './pages/shareartefact/shareartefact.component';
import { ShareCollectionDetailComponent } from './pages/sharecollectiondetail/sharecollectiondetail.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { RoleGuard } from './services/role-guard.service';
import { VerifyemailComponent } from './pages/verifyemail/verifyemail.component';
const routes: Routes = [
  { path: "", component: SigninComponent, canActivate: [NonAuthGuardService] },
  { path: "signin", component: SigninComponent, canActivate: [NonAuthGuardService]},
  { path: "forgot", component: ForgotpasswordComponent },
  { path: "verify-email/:token", component: VerifyemailComponent },
  { path: "reset-password/:token", component: ResetpasswordComponent },
  { path: "signup", component: SignupComponent, canActivate: [NonAuthGuardService] },
  { path: "signupsubscription", component: SignupsubscriptionComponent },

  { path: "company", component: CompanyComponent, canActivate: [AuthGuardService, RoleGuard],
  data: { roles : ['Super-admin'] } },
  { path: "shared-collections/:token", component: ShareComponent },
  { path: "shared-collections-detail/:artefactId", component: ShareCollectionDetailComponent },
  { path: "shared-artefacts/:token", component: ShareartefactComponent },

  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule), canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
function canActivate(arg0: (m: typeof import("./pages/dashboard/dashboard.module")) => typeof import("./pages/dashboard/dashboard.module").DashboardModule, canActivate: any, arg2: (typeof AuthGuardService)[]): import("@angular/core").Type<any> | import("@angular/core").NgModuleFactory<any> | import("rxjs").Observable<import("@angular/core").Type<any>> | Promise<any> {
  throw new Error('Function not implemented.');
}

