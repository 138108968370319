<div class="signinWrap">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="formContent">

                    <div class="formData">
                        <div class="logoWrap">
                            <img src="assets/img/logo-black.png" class="img-fluid" alt="" />
                            <!-- <h2>Collection viewing system</h2> -->
                        </div>
                        <div class="topData">
                            <h2 class="">Set Password </h2>
                            <p></p>
                        </div>
                        <div class="fromWrap">
                            <form [formGroup]="passwordForm" (ngSubmit)="forgotPassword()" novalidate>
                                <ul>
                                    <li class="full">
                                        <div class=" inputField form-group">
                                            <input type="{{showEyePassword ? 'text' : 'password'}}"
                                                placeholder="Password" formControlName="password" class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                            <img (click)="showEyePassword = !showEyePassword"
                                                src="{{showEyePassword ? 'assets/img/eye-show.svg' : 'assets/img/eye-hide.svg' }}"
                                                class="img-fluid" alt="" />
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <div *ngIf="f.password.errors.required">Password is required</div>
                                                <div *ngIf="f.password.errors.pattern">Password must be
                                                    (Min 8 character, 1 Upper Case, 1 Special, 1 Number)</div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="full">
                                        <div class=" inputField form-group">
                                            <input type="{{showEyeCPassword ? 'text' : 'password'}}"
                                                placeholder="Confirm Password" formControlName="confirmPassword"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                                            <img (click)="showEyeCPassword = !showEyeCPassword"
                                                src="{{showEyeCPassword ? 'assets/img/eye-show.svg' : 'assets/img/eye-hide.svg' }}"
                                                class="img-fluid" alt="" />
                                            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is
                                                    required
                                                </div>
                                                <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="full">
                                        <div class="form-group btnSign">
                                            <button type="submit" class="btn-send">Save</button>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>