import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { element } from 'protractor';
import { link } from 'node:fs';
@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css']
})
export class ShareComponent implements OnInit {
  private apiURL = environment.apiURL;

  token: any
  user_token: any;
  url: any;
  viewCollectionData: any;
  artefactData: any;
  imageUrl: any;
  collectionowner: any;
  collectionName: any;
  collectionimg: any;
  collectiondescr: any;
  constructor(private router: ActivatedRoute, public http: HttpClient, private routerN: Router) {

  }

  ngOnInit(): void {
    this.token = this.router.snapshot.paramMap.get('token')
    console.log("sharetoken", this.token)
    this.url = this.apiURL + "shared-collections/" + this.token
    this.http.get<any>(this.url)
      .subscribe(data => {
        this.http.post<any>(this.apiURL + 'artefacts/shared-list', { "company_id": JSON.stringify(data.data.data.company_id), "collection_id": JSON.stringify(data.data.data.id) })
          .subscribe(dataArtefact => {
            if (data.status == "1" && dataArtefact.data.artefacts.length > 0) {
              this.artefactData = dataArtefact.data.artefacts;

              let c: any = {};
              data.data.data.collection_values.map((i: { input_label: any; input_values: any; }) => { c[i.input_label] = i.input_values })
              this.collectionName = c["Collection Name"];
              this.collectionimg = c["Upload Image"];
              console.log("  this.collectionimg", this.collectionimg);

              this.collectiondescr = c["Description"];
            }
            else {
              this.artefactData = null;
            }
          },
          )

        /* this.artefactData.files.forEach((element: { link: String }) => {
          let files = element.link
          console.log(files)
        }); */
        /*    this.imageUrl = data.data.data.artefacts.files[0].link
           console.log(this.imageUrl) */
      });
  }

  gotoDetailsArtfact = (id: any) => {
    this.routerN.navigate([`/shared-collections-detail/${id}`]);
  }
}
