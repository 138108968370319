import { Component, NgZone } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'cumulus';

  headerFooter: any

  constructor(private zone: NgZone, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log(event.url)
        if (event.url === '/' || event.url === "/signin" || event.url === "/signin"|| event.url.includes('reset-password') || event.url.includes('verify-email') || event.url === "/forgot" || event.url === "/signup" || event.url === "/signupsubscription" || event.url.indexOf('shared-collections') > -1 || event.url.indexOf('shared-artefacts') > -1) {
          this.headerFooter = false
        }
        else {
          this.headerFooter = true
        }
      }
    });
  }
  ngOnInit() {

  }
}



