<div class="headerWrap">
    <nav class="navbar">
        <div class="container">
            <div class="navbar-header">
                <a class="navbar-brand logoWrap1">
                    <img src="assets/img/logo.png" class="img-fluid imgLogo1" alt="" />
                </a>

            </div>
            <ul class="nav">

                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['/signin']">
                        <button class="btn-primary" data-dismiss="modal">Log in</button>
                    </a>
                </li>

                <li [routerLinkActive]="['active']">
                    <a [routerLink]="['/signup']">
                        <button class="btn-primary" data-dismiss="modal">Register</button>
                    </a>
                </li>


            </ul>
        </div>
    </nav>
</div>
<div class="container" style="color: #606060;">
    <br>
    <div>
        <h3>
            <img *ngIf="collectionimg" src="{{collectionimg}}" alt="" class="img-fluid" style="width: 4%;" />
            <img *ngIf="!collectionimg" src="assets/img/dummyarefacts.png" alt="" class="img-fluid" style="width: 4%;"/>
            <span class="px-3 mt-2">{{collectionName}}</span>
        </h3>
        <p style="color: grey;">{{collectiondescr}}</p>
        <br>
    </div>

    <div class="row">
        <div class="col-md-6" *ngFor="let data of artefactData">
            <br>
            <!-- (click)="gotoDetailsArtfact(data.id)" -->
            <ul class="card_box" >
                <li>
                    <!-- <div *ngFor="let img of data.files" style="margin-top: -12px;">
                        <img src="{{img.link}}" alt="" class="" />
                    </div> -->
                    <div *ngIf="!data.thumbnail"
                        style="margin-top: -12px;border: 1px solid black;border: 1px solid #5db8b2;height: 140px;">
                    </div>
                    <div *ngIf="data.thumbnail" style="margin-top: -12px;">
                        <img src="{{data.thumbnail}}" alt="" class="" />
                    </div>
                </li>
                <li>
                    <h4>{{data.title}}</h4>
                    <p>{{data.description}}</p>
                    <p>
                        <img src="assets/img/ico-user-1.png" alt="" class="img-icon" />
                        {{data.artists}}
                    </p>
                    <p>
                        <img src="assets/img/ico-card.png" alt="" class="img-icon" />
                        {{data.period}}
                    </p>
                </li>
            </ul>
        </div>

    </div>
</div>