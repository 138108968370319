import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { ManageService } from 'src/app/services/manage.service';
import { SigninComponent } from '../signin/signin.component';
declare var Razorpay: any;
@Component({
  selector: 'app-signupsubscription',
  templateUrl: './signupsubscription.component.html',
  styleUrls: ['./signupsubscription.component.css']
})
export class SignupsubscriptionComponent implements OnInit {
  subscriptionList: any;
  role: any;
  userSubscriptionTypeId: any;
  companyId: any = history.state.companyId;
  email: any = history.state.email;
  password: any = history.state.password;

  // companyId: any = 4
  // email: any = 'nikhil@gmail.com'
  // password: any = '123456';

  constructor(private manageService: ManageService, private formBuilder: FormBuilder, private router: Router, private toastr: ToastrService, private authService: AuthService) {
  }

  ngOnInit(): void {
    this.SignIn();
  }

  SignIn = () => {
    this.authService.login(this.email, this.password).subscribe(
      data => {
        if (data.status == "1") {
          localStorage.setItem("LoggedInUser", data.data.token);
          this.getSubscriptionList()
        }
      })
  }

  getSubscriptionList() {
    this.manageService.getSubscriptionListAPI().subscribe(
      data => {
        console.log(data.message)
        if (data.status == "1") {
          this.subscriptionList = data.data.data;
          this.getCompanyDetails()
        }
        else {
          this.subscriptionList = data.message
        }
      })
  }

  getCompanyDetails = () => {
    this.authService.getCompanyById(this.companyId, null).subscribe(
      data1 => {
        this.userSubscriptionTypeId = data1.data.subscription_id;
      })
  }

  subscribePlan = (SubscriptionId: any) => {
    this.manageService.subscribePlanAPI(SubscriptionId).subscribe(
      data => {
        console.log(data.message)
        if (data.status == "1") {
          if (data.data === "") {
            this.toastr.success(data.message);
            localStorage.setItem("SubscriptionType", 'FREE');
            window.location.reload();
          } else {
            this.payWithRazor(data.data, SubscriptionId);
          }
        }
      }, error => {
        this.toastr.success(error.message);
      })
  }

  payWithRazor(val: any, SubscriptionId: any) {
    const options: any = {
      key: val.razorpayKey,
      amount: val.amount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'CUMULUS', // company name or product name
      description: '',  // product description
      image: 'http://15.206.230.168/assets/img/logo.png', // company logo or product image
      order_id: val.rpOrderId, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      prefill: {
        name: val.name,
        email: val.email,
        contact: val.contactNumber
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#512378'
      }
    };
    options.handler = ((response: any, error: any) => {
      options.response = response;
      response.order_id = val.order_id;
      console.log("frp", response);
      console.log("op", options);
      // call your backend api to verify payment signature & capture transaction

      let data = {
        "order_id": val.my_order_id,
        "razorpay_signature": response.razorpay_signature,
        "razorpay_payment_id": response.razorpay_payment_id,
        "razorpay_order_id": response.razorpay_order_id,
        "subscription_id": JSON.stringify(SubscriptionId)
      }
      this.manageService.subscribePlanCompletePaymentAPI(data).subscribe(
        data => {
          this.toastr.success(data.message);
          localStorage.setItem("SubscriptionType", 'FREE');
          this.GotoDashboard();
        })
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });
    const rzp = new Razorpay(options);
    rzp.open();
    console.log("start msg");
  }

  GoToSignIn = () => {
    localStorage.removeItem("LoggedInUser");
    this.router.navigate(['./signin']);
  }

  GotoDashboard = () => {
    this.authService.login(this.email, this.password).subscribe(
      data => {
        if (data.status == "1") {
          localStorage.setItem("user_id", data.data.id);
          localStorage.setItem("LoggedInUser", data.data.token);
          localStorage.setItem("member_type", data.data.member_type);
          if (data.data.permissions) localStorage.setItem("permissions", JSON.stringify(data.data.permissions));
          if (data.data.access) {
            let access = data.data.access.map((i: { access_name: any; }) => { return i.access_name })
            localStorage.setItem("access", access)
          }
          if (data.data.role) {
            localStorage.setItem("Role", data.data.role);
          } else {
            localStorage.setItem("Role", 'Member');
          }
          if (data.data.role_id) {
            if (data.data.is_admin == 1) {
              this.authService.getCompanyById(data.data.company_id, data.data.token).subscribe(
                data1 => {
                  localStorage.setItem("SubscriptionType", data1.data.subscription_type);
                  if (data.data.role === "Super-user") {
                    localStorage.setItem("companies_details", data.data.company_id)
                    localStorage.setItem("Role", data.data.role);
                    this.router.navigate(['./dashboard/home']);
                  } else {
                    this.toastr.error("Invalid")
                  }
                })

            }
            else {
              this.authService.getCompanyById(data.data.company_id, data.data.token).subscribe(
                data1 => {
                  localStorage.setItem("SubscriptionType", data1.data.subscription_type);
                  localStorage.setItem("companies_details", data.data.company_id)
                  localStorage.setItem("Role", "Member");
                  this.router.navigate(['./dashboard/home']);

                })
            }
          }
          else {
            if (data.data.role === "Super-admin") {
              localStorage.setItem("Role", data.data.role);
              this.router.navigate(['/company']);
            }
            else {
              this.toastr.error("Invalid")
            }
          }

        }
        else {
          this.toastr.error(data.message)
        }
      }, error => {
        this.toastr.error(error.error.message)
      })
  }
}
