import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html'
})
export class ResetpasswordComponent implements OnInit {

  f_email: any;
  showerror: any = false;
  showEyePassword = false;
  showEyeCPassword = false;
  passwordForm: FormGroup = {} as FormGroup;;
  submitted = false;
  token: any;

  constructor(private authService: AuthService, private formBuilder: FormBuilder, private toastr: ToastrService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    console.log("token", this.token);

    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      confirmPassword: ['', Validators.required],
    });
  }
  get f() { return this.passwordForm.controls; }


  forgotPassword() {
    this.submitted = true;
    if (this.passwordForm.invalid) {
      return;
    }
    let body = {
      "token": this.token,
      "password": this.passwordForm.value.password,
      "confirm_password": this.passwordForm.value.confirmPassword
    }

    this.authService.resetPassword(body).subscribe(
      data => {
        if (data.status == "1") {
          this.toastr.success(data.message)
          this.router.navigate(['./signin']);
        }
        else {
          this.toastr.error(data.message)
        }
      })
  }
}

