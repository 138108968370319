<ng-container>
    <div
      class="modal-backdrop-root"
      (click)="handleBackdropClick($event)"
    ></div>
    <div class="modal-wrapper">
      <div [class]="containerClasses" [style]="containerStyles">
        <img src="assets/img/x-mark-white.png" alt="" class="cross-icon position-absolute" (click)="handleBackdropClick($event)">
        <div class="m-auto">
            <img [src]="src" alt="" [ngStyle]="{'max-width': width, 'max-height': height}">
        </div>
        <i class="fas fa-chevron-left left-icon" (click)="handleNexPrevClick('prev')"></i>
        <i class="fas fa-chevron-right right-icon" (click)="handleNexPrevClick('next')"></i>
      </div>
    </div>
</ng-container>
