<div class="headerWrap headerNewWrap">
  <nav class="navbar">
    <div class="container-fluid">
      <div class="navbar-header">
        <a class="navbar-brand logoWrap1" *ngIf="role=='Super-admin'" [routerLink]="['/company']">
          <img src="assets/img/logo.png" class="img-fluid imgLogo1" alt="" />
        </a>
        <a class="navbar-brand logoWrap1" *ngIf="role=='Super-user' || role=='Member'"
          [routerLink]="['dashboard/home']">
          <img src="assets/img/logo.png" class="img-fluid imgLogo1" alt="" />
        </a>

        <a class="navbar-brand" *ngIf="role=='Super-admin'" [routerLink]="['/company']">
          <img src="{{companyLogo}}" class="img-fluid imgLogo2" alt="">
        </a>
        <a class="navbar-brand" *ngIf="role=='Super-user' || role=='Member'"
          [routerLink]="['dashboard/settings/profile']">
          <img src="{{companyLogo}}" class="img-fluid imgLogo2" alt="">
        </a>


        <button class="btn btnMenubar" id="menu-toggle" (click)="togglemenu($event)" style="position:absolute;">
          <span class="fas fa-bars"></span>
        </button>
      </div>
      <ul class="nav">
        <li class="dropdown">
          <a class="dropdown-toggle" data-toggle="dropdown" role="button"
            aria-expanded="false" [ngClass]="{'cursor-default': loginUserData?.role !== 'Super-admin'}">
            <!-- <img *ngIf="!userDetails?.image" src="assets/img/user-img-2.jpg" class="img-fluid" alt="" /> -->
            <div *ngIf="!userDetails?.image" class="profile-name">{{getProfileName()}}</div>
            <img *ngIf="userDetails?.image" src="{{userDetails.image}}" class="img-fluid" alt="" />
            <p class="mr-0"> {{loginUserData.name}} <span>{{ role }}</span></p>
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" *ngIf="loginUserData?.role === 'Super-admin'">
            <a class="dropdown-item" [routerLink]="['company']">Dashboard</a>
            <a class="dropdown-item" [routerLink]="['dashboard/settings/profile']">Settings</a>
            <a class="dropdown-item" (click)="onLogout()" href="javascript:void(0)">Log Out</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</div>
