import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  f_email: any;
  showerror: any = false;
  constructor(private authService: AuthService, private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
  }
  forgotPassword() {
    this.showerror = false;
    if (!this.f_email) this.showerror = true;
    let body = {
      email: this.f_email
    }

    console.log(this.f_email)
    this.authService.forgotPassword(body).subscribe(
      data => {
        if (data.status == "1") {
          this.toastr.success(data.message)
          this.router.navigate(['./signin']);
        }
        else {
          this.toastr.error(data.message)
        }
      }, error => {
        console.log(error)
        this.toastr.error(error?.error?.text);
      })
  }
}
