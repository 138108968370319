import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from 'src/app/services/must-match.validator';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  registerForm: FormGroup = {} as FormGroup;
  submitted = false;
  is_individual = 1;

  showEyePassword = false;
  showEyeCPassword = false;

  htmldata: any = '';
  htmldata2: any = '';
  form: any = {
    company_name: null,
    c_email: null,
    address: null,
    name: null,
    email: null,
    mobile: null,
    password: null,
    c_password: null,
  }
  isChecked: boolean = false;
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private toastr: ToastrService) {

    authService.getPrivacyPolicy().subscribe(
      data => {
        this.htmldata = data.data;
      })
    authService.getTermsCondition().subscribe(
      data => {
        this.htmldata2 = data.data;
      })
  }

  toggleCompanyIndividual = (d: any) => {
    this.is_individual = d;
    this.submitted = false;
    switch (d) {
      case 0:
        this.registerForm = this.formBuilder.group({
          name: ['', Validators.required],
          // mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
          mobile: ['', [Validators.required]],
          email: ['', [Validators.required, Validators.email]],
          password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
          confirmPassword: ['', Validators.required],
          acceptTerms: [false, Validators.requiredTrue],
          company_name: ['', Validators.required],
          c_email: ['', [Validators.required, Validators.email]],
          address: ['', Validators.required],
          country_code: ['+91', [Validators.required, Validators.pattern("^[+][0-9]*$")]],
        }, {
          validator: MustMatch('password', 'confirmPassword')
        });
        break;

      case 1:
        this.registerForm = this.formBuilder.group({
          name: ['', Validators.required],
          // mobile: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
          mobile: ['', [Validators.required]],
          email: ['', [Validators.required, Validators.email]],
          password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
          confirmPassword: ['', Validators.required],
          acceptTerms: [false, Validators.requiredTrue],
          country_code: ['+91', [Validators.required, Validators.pattern("^[+][0-9]*$")]],
        }, {
          validator: MustMatch('password', 'confirmPassword')
        });
        break;
    }
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      mobile: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      confirmPassword: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue],
      country_code: ['+91', [Validators.required, Validators.pattern("^[+][0-9]*$")]],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }
  get f() { return this.registerForm.controls; }


  onSubmit() {
    this.submitted = true;
    console.log(this.registerForm.value)
    if (this.registerForm.invalid) {
      return;
    } else {
      console.log(this.registerForm.value)
      const formData = new FormData();
      formData.append("name", this.registerForm.value.name);
      formData.append("email", this.registerForm.value.email);
      formData.append("mobile", this.registerForm.value.mobile);
      formData.append("password", this.registerForm.value.password);
      formData.append("c_password", this.registerForm.value.confirmPassword);
      formData.append("is_individual", JSON.stringify(this.is_individual));
      if (this.is_individual === 0) {
        formData.append("company_name", this.registerForm.value.company_name);
        formData.append("c_email", this.registerForm.value.c_email);
        formData.append("address", this.registerForm.value.address);
      }
      this.authService.register(formData).subscribe(
        data => {
          if (data.status) {
            this.router.navigate(['./signupsubscription'], {
              state:
              {
                companyId: data.data.company_id,
                email: this.registerForm.value.email,
                password: this.registerForm.value.password,
              }
            });
          }
          else {
            this.toastr.error(data.message)
          }

        }, error => {
          this.toastr.error(error.error.message)
        })

    }
  }



}
