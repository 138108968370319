import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map, catchError } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  loginForm: FormGroup = {} as FormGroup

  submitted = false;
  showEye = false;

  rememberMe: boolean | undefined;

  errorMessage: any;
  constructor(private formBuilder: FormBuilder, private authService: AuthService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      // password: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]{8}$")]],

    });
  }
  get f() { return this.loginForm.controls; }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      console.log(this.loginForm.value)
      // const { email, password } = this.form;
      // console.log(this.form)
      const { email, password } = this.loginForm.value;
      this.authService.login(email, password).subscribe(
        data => {
          if (data.status == "1") {
            localStorage.setItem("user_id", data.data.id);
            localStorage.setItem("LoggedInUser", data.data.token);
            localStorage.setItem("member_type", data.data.member_type);
            if (data.data.permissions) localStorage.setItem("permissions", JSON.stringify(data.data.permissions));
            if (data.data.access) {
              let access = data.data.access.map((i: { access_name: any; }) => { return i.access_name })
              localStorage.setItem("access", access)
            }
            if (data.data.role) {
              localStorage.setItem("Role", data.data.role);
            } else {
              localStorage.setItem("Role", 'Member');
            }
            if (data.data.role_id) {
              if (data.data.is_admin == 1) {
                this.authService.getCompanyById(data.data.company_id, data.data.token).subscribe(
                  data1 => {
                    localStorage.setItem("SubscriptionType", data1.data.subscription_type);
                    localStorage.setItem("companyLogo", data1.data.image);
                    if (data.data.role === "Super-user") {
                      localStorage.setItem("companies_details", data.data.company_id)
                      localStorage.setItem("Role", data.data.role);
                      this.router.navigate(['./dashboard/home']);
                    } else {
                      this.toastr.error("Invalid")
                    }
                  })

              }
              else {
                this.authService.getCompanyById(data.data.company_id, data.data.token).subscribe(
                  data1 => {
                    localStorage.setItem("SubscriptionType", data1.data.subscription_type);
                    localStorage.setItem("companyLogo", data1.data.image);
                    localStorage.setItem("companies_details", data.data.company_id)
                    localStorage.setItem("Role", "Member");
                    this.router.navigate(['./dashboard/home']);

                  })
              }
            }
            else {
              if (data.data.role === "Super-admin") {
                localStorage.setItem("Role", data.data.role);
                this.router.navigate(['/company']);
              }
              else {
                this.toastr.error("Invalid")
              }
            }

          }
          else {
            this.toastr.error(data.message)
          }
        }, error => {
          this.toastr.error(error.error.message)
        })
    }

  }
}

