<div class="mainContent companyPageWrap">
    <div class="middleContent">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="topData">
                        <div class="leftPanel">
                            <h2>Company <span>({{this.companyCount}})</span></h2>
                        </div>
                        <div class="rightPanel w-50">
                            <div class="searchWrap">
                                <img src="assets/img/ico-search.png" class="img-fluid" alt="" />
                                <input (keyup.enter)="onSearch($event?.target)" placeholder="Search" type="text" name="search" #comSearch>
                            </div>
                            <button class="btn btn-primary" (click)="onSearch(comSearch)">Search</button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="row-border hover">
                            <thead>
                                <tr style="cursor: pointer;">
                                    <th (click)="onsort('name')">
                                        Company Name
                                        <button *ngIf="pagination.sort_by=='name'" class="btn btnSort">
                                            <img *ngIf="pagination.sort_direction=='ASC'"
                                                src="assets/img/ico-sort-down.png" alt="" class="img-fluid" />
                                            <img *ngIf="pagination.sort_direction=='DESC'"
                                                src="assets/img/ico-sort-up.png" alt="" class="img-fluid" />
                                        </button>
                                    </th>
                                    <th (click)="onsort('collections')">Collections
                                        <button *ngIf="pagination.sort_by=='collections'" class="btn btnSort">
                                            <img *ngIf="pagination.sort_direction=='ASC'"
                                                src="assets/img/ico-sort-down.png" alt="" class="img-fluid" />
                                            <img *ngIf="pagination.sort_direction=='DESC'"
                                                src="assets/img/ico-sort-up.png" alt="" class="img-fluid" />
                                        </button>
                                    </th>
                                    <th (click)="onsort('artefacts')">Artefacts
                                        <button *ngIf="pagination.sort_by=='artefacts'" class="btn btnSort">
                                            <img *ngIf="pagination.sort_direction=='ASC'"
                                                src="assets/img/ico-sort-down.png" alt="" class="img-fluid" />
                                            <img *ngIf="pagination.sort_direction=='DESC'"
                                                src="assets/img/ico-sort-up.png" alt="" class="img-fluid" />
                                        </button>
                                    </th>
                                    <th (click)="onsort('contact_person_name')">Users
                                        <button *ngIf="pagination.sort_by=='contact_person_name'" class="btn btnSort">
                                            <img *ngIf="pagination.sort_direction=='ASC'"
                                                src="assets/img/ico-sort-down.png" alt="" class="img-fluid" />
                                            <img *ngIf="pagination.sort_direction=='DESC'"
                                                src="assets/img/ico-sort-up.png" alt="" class="img-fluid" />
                                        </button>
                                    </th>
                                    <th (click)="onsort('subscription_plan')">Subscription
                                        <button *ngIf="pagination.sort_by=='subscription_plan'" class="btn btnSort">
                                            <img *ngIf="pagination.sort_direction=='ASC'"
                                                src="assets/img/ico-sort-down.png" alt="" class="img-fluid" />
                                            <img *ngIf="pagination.sort_direction=='DESC'"
                                                src="assets/img/ico-sort-up.png" alt="" class="img-fluid" />
                                        </button>
                                    </th>
                                    <th (click)="onsort('expired_on')">Subscription Validity
                                        <button *ngIf="pagination.sort_by=='expired_on'" class="btn btnSort">
                                            <img *ngIf="pagination.sort_direction=='ASC'"
                                                src="assets/img/ico-sort-down.png" alt="" class="img-fluid" />
                                            <img *ngIf="pagination.sort_direction=='DESC'"
                                                src="assets/img/ico-sort-up.png" alt="" class="img-fluid" />
                                        </button>
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="!companiesList || companiesList.length === 0">
                                <tr>
                                    <td colspan="7" class="text-center">No data found.</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="companiesList && companiesList.length > 0">
                                <tr *ngFor="let campanies of companiesList | paginate: {
                                    itemsPerPage: pagination.limit,
                                    currentPage: pagination.page,
                                    totalItems: pagination.totalItems
                                  } ">
                                    <!-- <td (click)=" gotoDashboard(campanies)"> -->
                                    <td>
                                        <h3>{{campanies.name}}</h3>
                                        <p>{{campanies.email}}</p>
                                    </td>
                                    <td>{{campanies.collections}}</td>
                                    <td>{{campanies.artefacts}}</td>
                                    <td>{{campanies.users}}</td>
                                    <td>{{campanies.subscription_plan}}<span></span></td>
                                    <td>{{campanies.expired_on | date : "dd/MM/yyyy hh:mm a"}}</td>
                                    <td>
                                        <!--  <button class="btn btnAction" data-toggle="modal" data-target="#c_edit"><img
                                                src="assets/img/ico-edit.png" alt=""
                                                (click)="getCompanyDetails(campanies.id)" class="img-fluid" /></button> -->
                                        <button class="btn btnAction" data-toggle="modal" data-target="#c_delete"
                                            (click)="setCompanyId(campanies.id)"><img src="assets/img/ico-trash.png"
                                                alt="" class="img-fluid icoDelete" /></button>
                                        <button class="btn btnAction" data-toggle="modal" data-target="#c_settings"><img
                                                (click)="getCompanyDetails(campanies.id)"
                                                src="assets/img/ico-settings.png" alt=""
                                                class="img-fluid icoSetting" /></button>
                                    </td>
                                    <!--Delete Settings-->
                                    <div class="modal fade deletePopup" id="c_delete" tabindex="-1" role="dialog"
                                        aria-labelledby="deleteLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-body">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="title">
                                                                    <h2>Delete Company</h2>
                                                                </div>
                                                                <div class="dataWrap">
                                                                    <p>Are you sure you want to delete ?</p>
                                                                    <div class="ctaWrap">
                                                                        <button class="btnCancel btn"
                                                                            data-dismiss="modal">Cancel</button>
                                                                        <button class="btn btnConfirm"
                                                                            data-dismiss="modal"
                                                                            (click)="deletedCompany()">Confirm</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <div class="py-2" style="float: right;">
                            <nav aria-label="...">
                                <ul class="pagination pagination-lg" *ngIf="this.pagination.totalPageCount>1">
                                    <li class="page-item"
                                        *ngFor='let in of counter(pagination.totalPageCount) ;let i = index'
                                        [ngClass]="{'active': selectedItem == i+1}" (click)="onPaginationClick(i+1)">
                                        <a class="page-link">{{i+1}}</a>
                                    </li>
                                </ul>
                            </nav>
                        </div> -->
                        <div *ngIf="this.pagination.totalPageCount>1">
                            <pagination-controls style="float: right; " style="float: right; "
                                (pageChange)="getPaginationData(( pagination.page = $event))">
                            </pagination-controls>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>

<!--Company Settings-->
<div class="modal fade formPopup companysettingPopup" id="c_settings" tabindex="-1" role="dialog"
    aria-labelledby="settingsLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="settingsLabel"><img src="assets/img/ico-back.png" data-dismiss="modal"
                        class="img-fluid" alt="" />Back | Company Profile settings</h5>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12" *ngIf="companyDetails">
                            <div class=" title">
                                <h2>Company Details</h2>
                            </div>
                            <form>
                                <ul>
                                    <li class="editImage">
                                        <div class="imgWrap">
                                            <img *ngIf="companyDetails.image" src="{{companyDetails.image}}"
                                                class="img-fluid" alt="companyDetails.image" />
                                            <img *ngIf="!companyDetails.image" src="assets/img/companydefult.png"
                                                class="img-fluid" alt="companyDetails.image" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="formField">
                                            <label>Company name<span>*</span> :</label>
                                            <input type="text" value="{{companyDetails.name}}"
                                                [readonly]="!childmessage" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="formField bgclrGray">
                                            <label>Email :</label>
                                            <input type="email" value="{{companyDetails.email}}"
                                                [readonly]="!childmessage" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="formField">
                                            <label>Company name<span>*</span> :</label>
                                            <textarea [readonly]="!childmessage">{{companyDetails.address}}</textarea>
                                        </div>
                                    </li>
                                    <!--  <li class="btnWrap">
                                        <button class="btn">Submit</button>
                                    </li> -->
                                </ul>
                            </form>
                            <div class="title">
                                <h2>Administrator Details</h2>
                            </div>
                            <form>
                                <ul>
                                    <li class="editImage">
                                        <div class="imgWrap">
                                            <img *ngIf="companyDetails.contact_person_profile"
                                                src="{{companyDetails.contact_person_profile}}"
                                                class="img-fluid userImg" />
                                            <img *ngIf="!companyDetails.contact_person_profile"
                                                src="assets/img/defaultUser.jpg" class="img-fluid userImg" />
                                        </div>
                                        <!-- <div class="upload-btn-wrapper">
                                            <button class="btn">Upload new picture</button>
                                            <input type="file" name="myfile" />
                                        </div>
                                        <button class="btn btnDelete">Delete</button> -->
                                    </li>
                                    <li>
                                        <div class="formField">
                                            <label>Name<span>*</span> :</label>
                                            <input type="text" value="{{companyDetails.contact_person_name}}"
                                                [readonly]="!childmessage" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="formField">
                                            <label>Email<span>*</span> :</label>
                                            <input type="text" value="{{companyDetails.contact_person_email}}"
                                                [readonly]="!childmessage" />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="formField">
                                            <label>Contact<span>*</span> :</label>
                                            <input type="text" value="{{companyDetails.contact_person_mobile}}"
                                                [readonly]="!childmessage" />
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Company edit-->
<div class="modal fade formPopup companysettingPopup" id="c_edit" tabindex="-1" role="dialog"
    aria-labelledby="settingsLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="settingsLabel"><img src="assets/img/ico-back.png" data-dismiss="modal"
                        class="img-fluid" alt="" />Back | Company Profile Edit</h5>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12" *ngIf="companyDetails">
                            <div class=" title">
                                <h2>Company Details</h2>
                            </div>
                            <form class="form-center" name="form" (ngSubmit)="onSubmit()" novalidate>
                                <ul>
                                    <li class="editImage">
                                        <div class="imgWrap">
                                            <img src="{{imageSrc}}" *ngIf="imageSrc" alt="" class="img-fluid" />
                                        </div>
                                        <div class="upload-btn-wrapper">
                                            <button class="btn">Upload new picture</button>
                                            <input type="file" name="myfile" (change)="comapnyProfileUpdate($event)" />
                                        </div>
                                        <button class="btn btnDelete" (click)="deleteCompanyProfile()">Delete</button>
                                    </li>
                                    <li>
                                        <div class="formField">
                                            <label>Company name<span>*</span> :</label>
                                            <input type="text" name="name" value="{{name}}" [(ngModel)]="name"
                                                required />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="formField ">
                                            <label>Email :</label>
                                            <input type="email" name="email" [(ngModel)]="email" value=" {{email}}"
                                                required />
                                        </div>
                                    </li>
                                    <li>
                                        <div class="formField">
                                            <label>Company address<span>*</span> :</label>
                                            <input type="text" name="address" [(ngModel)]="address" required
                                                value="{{address}}" />
                                            <!-- <textarea>{{companyDetails.address}}</textarea> -->
                                        </div>
                                    </li>
                                    <li class="form-group btnWrap">
                                        <button class="btn">Submit</button>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>