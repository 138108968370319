<div class="signinWrap">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="formContent">

                    <div class="formData">
                        <div class="logoWrap">
                            <img src="assets/img/logo-black.png" class="img-fluid" alt="" />
                            <!-- <h2>Collection viewing system</h2> -->
                        </div>
                        <div class="topData">
                            <h2>Reset password</h2>
                            <p>Provide us the email id of your Cumulus account and we will send you an email
                                instructions to reset your password.</p>
                        </div>
                        <div class="fromWrap">
                            <form name="form" (ngSubmit)="forgotPassword()" novalidate>
                                <ul>
                                    <li class="full mb-5">
                                        <div class="inputField form-group">
                                            <input type="email" placeholder="Email" class="" name="f_email"
                                                [(ngModel)]="f_email" required>
                                            <!-- <img src="assets/img/ico-mail.png" class="img-fluid" alt="" /> -->
                                            <div class="alert alert-danger" role="alert" *ngIf="showerror">
                                                Email is required!
                                            </div>
                                        </div>
                                    </li>
                                    <!-- <li class="bottomFields full">
                                        <div class="leftWrap">

                                        </div>
                                        <div class="rightWrap">
                                            <a routerLink="/signin">Sign in</a>
                                        </div>
                                    </li> -->

                                    <li class="full">
                                        <div class="form-group btnSign">
                                            <button type="button" class="btn-transperent"
                                                [routerLink]="['/signin']">Cancel</button>
                                            <button type="submit" class="btn-send">Send me instructions</button>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>