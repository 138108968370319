<div class="mainContent managePageWrap signupsubscription">
    <div class="container">
        <div class="row justify-content-start">
            <div class="pricingtableWrap">
                <div class="container">
                    <!-- For Super User -->
                    <label>Choose a plan</label>
                    <div class="row">
                        <div *ngIf="this.subscriptionList == 'No data found'">
                            <p>{{this.subscriptionList}}</p>
                        </div>
                        <div class="col-md-4" *ngFor="let data of subscriptionList">
                            <!-- priceFree, pricePremium, pricePremiumPlus -->
                            <div
                                [ngClass]="[userSubscriptionTypeId===data.id   ? 'pricingBox priceFree' : 'pricingBox pricePremium']">
                                <div class="titleWrap">
                                    <p>{{data.title}}</p>
                                    <!-- <h2><span>{{data.subscription_cost | currency:"INR":"symbol"}}</span> /month</h2> -->
                                    <h2><span> <span class="rs-icon">&#8377;</span> {{data.subscription_cost }}</span>
                                        /month</h2>
                                </div>
                                <div class="dataWrap">
                                    <p>{{data.descr}}</p>
                                </div>
                                <div class="ctaWrap">
                                    <button class="btn btnRed" *ngIf="userSubscriptionTypeId===data.id"
                                        (click)="GotoDashboard()">Current</button>
                                    <button class="btn" *ngIf="userSubscriptionTypeId!==data.id"
                                        (click)="subscribePlan(data.id)">Select</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <button [routerLink]="['/']">Back to SignIn</button> -->
                </div>
            </div>
        </div>
    </div>
</div>
