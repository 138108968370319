import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CumulusService {
  private apiURL = environment.apiURL;

  constructor(
    private auth: AuthService,
    private router: Router,
    public http: HttpClient) { }

  getAccessToken(): string {
    if (this.auth.currentUserValue.token === null) {
      return '';
    } else {
      return this.auth.currentUserValue.token;
    }
  }

  getTotalCount(company_id: any) {
    return this.http.get(this.apiURL + 'total-count?company_id=' + company_id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          console.log('data', data);
          return data;
        }));
  }

  getCollectionList(id: any, page: any, limit: any, search: any, sort_by: any, sort_direction: any, selectedArtafactId: any = null) {
    let searchString = "";
    let artefactString = "";
    if (search) searchString = `&search=${search}`
    if (selectedArtafactId) artefactString = `&artefact_id=${selectedArtafactId}`
    return this.http
      .get(this.apiURL + `collections?company_id=${id}&page=${page}&sort_by=${sort_by}&sort_direction=${sort_direction}&limit=${limit}${searchString}${artefactString}`, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          console.log('data', data);
          return data;
        })
      );
  }

  assignCollection(data: any) {
    return this.http
      .post<any>(this.apiURL + 'assign-collection', data, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  unassignCollection(data: any) {
    return this.http
      .post<any>(this.apiURL + 'unassign-collection', data, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  getFilterOptionsAPI() {
    return this.http
      .get<any>(this.apiURL + 'artefact-filter-option', this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  getArtefactsList(data: any) {
    return this.http
      .post<any>(this.apiURL + 'artefacts/list', data, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  getMembers(id: any, page: any, limit: any, search: any, sort_by: any, sort_direction: any) {
    let searchString = ""; if (search) searchString = `&search=${search}`
    let sortString = ""; if (sort_by) sortString = `&sort_by=${sort_by}`
    let sortDirectionString = ""; if (sort_direction) sortDirectionString = `&sort_direction=${sort_direction}`
    return this.http
      .get<any>(this.apiURL + `members?company_id=${id}&page=${page}&limit=${limit}${searchString}${sortString}${sortDirectionString}`, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  getArtistAPI(id: any, page: any, limit: any, search: any, sort_by: any, sort_direction: any) {
    let searchString = ""; if (search) searchString = `&search=${search}`
    let sortString = ""; if (sort_by) sortString = `&sort_by=${sort_by}`
    let sortDirectionString = ""; if (sort_direction) sortDirectionString = `&sort_direction=${sort_direction}`
    return this.http
      .get<any>(this.apiURL + `artists?company_id=${id}&page=${page}&limit=${limit}${searchString}${sortString}${sortDirectionString}`, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  getArtistByIdAPI(id: any) {
    return this.http
      .get<any>(this.apiURL + `artists/${id}`, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  deleteArtistAPI(id: any) {
    return this.http
      .delete<any>(this.apiURL + 'artists?id=' + id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => { return data; }),
        catchError((err) => {
          console.error('gm', err);
          throw err;
        })
      );
  }

  updateMemberCollectionRoleAPI(data: any) {
    return this.http
      .put<any>(this.apiURL + 'collection-members', data, this.auth.getJSONHeader())
      .pipe(
        map((data): any => { return data; }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  addMemberCollectionRoleAPI(data: any) {
    return this.http
      .post<any>(this.apiURL + 'collection-members', data, this.auth.getJSONHeader())
      .pipe(
        map((data): any => { return data; }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  deleteMemberCollectionRoleAPI(id: any) {
    return this.http
      .delete<any>(this.apiURL + 'collection-members?id=' + id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => { return data; }),
        catchError((err) => {
          console.error('gm', err);
          throw err;
        })
      );
  }

  getCompanies(data: any) {
    return this.http.post(this.apiURL + 'companies', data, this.auth.getJSONHeader()).pipe(
      map((data): any => {
        return data;
      })
    );
  }

  deletedCompany(company_id: any) {
    return this.http
      .delete<any>(this.apiURL + 'company?id=' + company_id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('gm', err);
          throw err;
        })
      );
  }

  getCompanyDetails(company_id: any) {
    return this.http
      .get<any>(this.apiURL + 'company/' + company_id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  comapnyEdit(name: any, email: any, address: any, id: any) {
    return this.http
      .post<any>(this.apiURL + 'company/profile-detail-update', { name, email, address, id }, this.auth.getJSONHeader()).pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  comapnyProfileUpdate(data: any) {
    return this.http
      .post<any>(this.apiURL + 'company/profile-update', data, this.auth.getImageHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  getArtifectsById(id: any) {
    return this.http.get<any>(this.apiURL + "artefacts/" + id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  deletedArtefacts(artefacts_Id: any) {
    return this.http.delete<any>(this.apiURL + "artefacts?id=" + artefacts_Id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  deletedArtefactFile(id: any) {
    return this.http.post<any>(this.apiURL + "artefact-file-remove", id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  changeArtefactPrimaryDisplay(queryParams: any) {
    return this.http.get<any>(this.apiURL + "change-default-file" + queryParams, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  uploadImage(body: any) {
    return this.http.post<any>(this.apiURL + "artefacts/files", body, this.auth.getImageHeader())
      .pipe(
        map((data): any => {
          return data;
        }), catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  addArtefacts(body: any) {
    return this.http.post<any>(this.apiURL + "artefacts", body, this.auth.getImageHeader())
      .pipe(
        map((data): any => {
          return data;
        }), catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  editArtefacts(body: any) {
    return this.http.put<any>(this.apiURL + "artefacts", body, this.auth.getImageHeader())
      .pipe(
        map((data): any => {
          return data;
        }), catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  getArtefactsStatus(body: any) {
    return this.http.post<any>(this.apiURL + "artefact-status", body, this.auth.getImageHeader())
      .pipe(
        map((data): any => {
          return data;
        }), catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  addCollections(formData: FormData) {
    return this.http.post<any>(this.apiURL + "collections", formData, this.auth.getImageHeader())
      .pipe(
        map((data): any => {
          return data;
        }), catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  editCollections(fdata: FormData) {
    return this.http.put<any>(this.apiURL + "collections", fdata, this.auth.getImageHeader())
      .pipe(
        map((data): any => {
          return data;
        }), catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  deletedcollection(collection_id: any) {
    return this.http.delete<any>(this.apiURL + "collections?id=" + collection_id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  collectionDetails(collection_id: any) {
    return this.http.get<any>(this.apiURL + "collections/" + collection_id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  collectionShare(collection_id: any) {
    return this.http.get<any>(this.apiURL + "share-collection?collection_id=" + collection_id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  artefactShare(id: any) {
    return this.http.get<any>(this.apiURL + "share-artefact?artefact_id=" + id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  deletedMembers(member_id: any) {
    return this.http.delete<any>(this.apiURL + "members?id=" + member_id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  registerMembers(body: any): Observable<any> {
    return this.http.post(this.apiURL + 'register-member', body, this.auth.getJSONHeader());
  }

  activeLogsList(page: any, limit: any, search: any, sort_by: any, sort_direction: any) {
    let searchString = "";
    if (search) searchString = `&search=${search}`
    return this.http.get<any>(this.apiURL + `logs?page=${page}&sort_by=${sort_by}&sort_direction=${sort_direction}&limit=${limit}${searchString}`, this.auth.getJSONHeader())

      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  generateReport(body: any) {
    let headers = new HttpHeaders()
    headers = headers.append('content-type', 'application/json')
    headers = headers.append('Accept', 'application/json')
    headers = headers.append('authorization', "jwt " + this.getAccessToken());
    return this.http.post<any>(this.apiURL + "export-logs", body, { 'headers': headers, responseType: 'blob' as 'json' })
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  getpermissionList() {
    return this.http.get<any>(this.apiURL + "roles/permissionList", this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  getCollectionMembersList(id: any) {
    return this.http.get<any>(this.apiURL + "collection-members?collection_id=" + id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  addArtists(data: any) {
    return this.http.post<any>(this.apiURL + "artists", data, this.auth.getImageHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  updateArtists(data: any) {
    return this.http.put<any>(this.apiURL + "artists", data, this.auth.getImageHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  searchArtists(data: any) {
    return this.http.post<any>(this.apiURL + "search-artists", data, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }
  memberEdit(body: any) {
    return this.http
      .post<any>(this.apiURL + 'profile-detail-update', body, this.auth.getJSONHeader()).pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }
  // Classifications
  getClassificationAPI(company_id: any, page: any = '', limit: any = '', search: any = '', sort_by: any = '', sort_direction: any = '') {
    let searchString = ""; if (search) searchString = `&search=${search}`
    return this.http.get(this.apiURL + `classification?company_id=${company_id}&page_no=${page}&sort_by=${sort_by}&sort_direction=${sort_direction}&limit=${limit}${searchString}`, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          console.log('data', data);
          return data;
        }));
  }

  // Classifications
  getClassificationListForDropdown() {
    return this.http.get(this.apiURL + `classification-list`, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          console.log('data', data);
          return data;
        }));
  }

  addClassificationAPI(data: any) {
    return this.http
      .post<any>(this.apiURL + 'classification', data, this.auth.getJSONHeader())
      .pipe(
        map((data): any => { return data; }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }


  updateClassificationAPI(data: any) {
    return this.http
      .put<any>(this.apiURL + 'classification', data, this.auth.getJSONHeader())
      .pipe(
        map((data): any => { return data; }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  deleteClassificationAPI(id: any) {
    return this.http
      .delete<any>(this.apiURL + 'classification?id=' + id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => { return data; }),
        catchError((err) => {
          console.error('gm', err);
          throw err;
        })
      );
  }

  // Artists Role
  getArtistsRoleAPI(page: any = '', limit: any = '', search: any = '', sort_by: any = '', sort_direction: any = '') {
    let searchString = ""; if (search) searchString = `&search=${search}`
    return this.http.get(this.apiURL + `artists-role?&page_no=${page}&sort_by=${sort_by}&sort_direction=${sort_direction}&limit=${limit}${searchString}`, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          console.log('data', data);
          return data;
        }));
  }

    // Artists Role List for select tag
    getArtistsRoleList() {
      return this.http.get(this.apiURL + `artists-role-list`, this.auth.getJSONHeader())
        .pipe(
          map((data): any => {
            console.log('data', data);
            return data;
          }));
    }

  getArtistsRoleListForDropdown() {
    return this.http.get(this.apiURL + `artists-role-list`, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          console.log('data', data);
          return data;
        }));
  }

  addArtistsRoleAPI(data: any) {
    return this.http
      .post<any>(this.apiURL + 'artists-role', data, this.auth.getJSONHeader())
      .pipe(
        map((data): any => { return data; }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }


  updateArtistsRoleAPI(data: any) {
    return this.http
      .put<any>(this.apiURL + 'artists-role', data, this.auth.getJSONHeader())
      .pipe(
        map((data): any => { return data; }),
        catchError((err) => {
          console.error('err', err);
          throw err;
        })
      );
  }

  deleteArtistsRoleAPI(id: any) {
    return this.http
      .delete<any>(this.apiURL + 'artists-role?id=' + id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => { return data; }),
        catchError((err) => {
          console.error('gm', err);
          throw err;
        })
      );
  }

  getArtefactActivityLog(id: any) {
    return this.http.get<any>(this.apiURL + "get-artifact-activity-logs/" + id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  addArtefactActivityLog(body: any) {
    return this.http.post<any>(this.apiURL + "add-artifact-activity-log", body, this.auth.getImageHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  removeArtefactActivityLog(id: any) {
    return this.http
      .delete<any>(this.apiURL + 'remove-artifact-activity-log?id=' + id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  getArtefactRelatedDoc(id: any) {
    return this.http.get<any>(this.apiURL + "get-artifact-related-documents/" + id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  addArtefactRelatedDoc(body: any) {
    return this.http.post<any>(this.apiURL + "add-artifact-related-documents", body, this.auth.getImageHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }

  removeArtefactRelatedDoc(id: any) {
    return this.http
      .delete<any>(this.apiURL + 'remove-artifact-related-documents?id=' + id, this.auth.getJSONHeader())
      .pipe(
        map((data): any => {
          return data;
        }),
        catchError((err) => {
          console.error("err", err);
          throw err;
        })
      )
  }
}
