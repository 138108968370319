import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.css'],
})
export class ViewImageComponent implements OnInit {
  @Input('src') src: string = '';
  @Input('images') images: any[] = [];
  @Input() isOpen: boolean = false;
  @Input() fullscreen: boolean = false;
  @Input() fullWidth: boolean = true;
  @Input() maxWidth: 'lg' | 'md' | 'sm' | 'xl' | 'xs' = 'sm';
  @Output() onBackdropClick: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Input() containerStyles: string = '';
  containerClasses: string = 'modal-container';
  width: string = `${window.screen.width}px`;
  height: string = `${innerHeight}px`;

  constructor() {}

  ngOnInit(): void {
    if (this.fullscreen) {
      this.containerClasses += ' modal-container-fullscreen';
    }
    if (this.maxWidth) {
      this.containerClasses += ` modal-container-${this.maxWidth}`;
    }
    if (this.fullWidth) {
      this.containerClasses += ' modal-container-fullwidth';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(this.width, this.height);
    if (changes.isOpen && changes.isOpen.currentValue) {
      document.body.classList.add('modal-open');
    }
    if (changes.isOpen && !changes.isOpen.currentValue) {
      document.body.classList.remove('modal-open');
    }
  }

  handleBackdropClick(e: any) {
    e.preventDefault();
    e.stopPropagation();
    this.onClose.emit();
  }

  handleNexPrevClick(action = 'next') {
    for (const i of this.images.keys()) {
      const element = this.images[i];
      if(element.isActive) {
        if(action === 'next') {
          if((i+1) === this.images.length) {
            this.src = this.images[0].image;
            this.images[i].isActive = 0;
            this.images[0].isActive = 1;
            return;
          } else {
            this.src = this.images[i+1].image;
            this.images[i].isActive = 0;
            this.images[i+1].isActive = 1;
            return;
          }
        } else {
          if((i-1) < 0) {
            this.src = this.images[this.images.length - 1].image;
            this.images[i].isActive = 0;
            this.images[this.images.length - 1].isActive = 1;
            return;
          } else {
            this.src = this.images[i-1].image;
            this.images[i].isActive = 0;
            this.images[i-1].isActive = 1;
            return;
          }
        }
      }
    }
  }
}
